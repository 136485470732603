import './header.css'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { Avatar, Badge } from 'antd';
import { Layout, Menu, Button, Row, Drawer, Icon, Select } from 'antd'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import routes from '../../routes'
import { pathOr } from 'ramda'
import { signout } from '../../containers/auth/actions'
const { Header: AntHeader } = Layout;
const SubMenu = Menu.SubMenu;
import company_icon from '../../images/company_icon.svg'
const Option = Select.Option
const resetAllState = () => ({ type: '@@reduxFirestore/CLEAR_DATA', preserve: { data: false, ordered: true } })
const resetState = () => ({type: '@@reduxFirestore/CLEAR_DATA', preserve: { data: ['companyIds', 'companies'], ordered: true }})

export class Header extends Component {

  state = {
    notification_drawer_visible: false,
    is_unread_notifications: true
  }

  componentDidMount = () => {
    this.idleLogout()
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth && prevProps.userLang !== this.props.userLang) {

      const { uid } = this.props.auth
      const lang = pathOr('EN', ['userLang'], this.props)
      if (lang !== 'EN') {
        this.props.changeLanguage(lang, uid)
      }
    }
  }

  getLanguage = () => {
    const { language } = this.props
    if (language && language == 'EN') {
      return 'English'
    } else if (language && language == 'NL') {
      return 'Nederlands'
    }
  }

  handleMenuClick = (e) => {
    //message.info('Click on menu item.');

  }

  onCloseNotifDrawer = () => {
    this.setState({
      notification_drawer_visible: false
    });
  };


  toggleNotificationList = () => {
    this.setState({
      notification_drawer_visible: !this.state.notification_drawer_visible,
      drawer_visible: false
    });
  };

  selectCompany = (value) => {

    const { myCompanies, history, companyName } = this.props
    if (myCompanies) {
      const company = myCompanies.filter(item => item[1] && item[1].id === value)[0]
      if (company[1].name === companyName) {
        //Already selected
        return;
      }
      const slug = company[1].slug
      history.push(`/company/${slug}/compare/energy`)
      this.props.resetState()
    }
  }
  logout = () => {
    this.props.resetAllState()
    this.props.signout()
  }

  idleLogout = () => {
    let _this = this;
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeydown = resetTimer;   
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    function resetTimer() {
        clearTimeout(t);
        t = setTimeout(_this.logout, 3600000);  // time is in milliseconds
    }
  }

  render() {

    const { signout, auth, myCompanies, t, superReseller, logo } = this.props
    const button_style = {
      height: '50px',
      width: '50px',
      borderRadius: '0px',
      border: '0px'
    }
    const width = window.innerWidth < 1024 ? '100vw' : '426px';
    const height = `${window.innerHeight - 100}px`;
    const authenticated = auth.isLoaded && !auth.isEmpty
    const pathname = this.props.location.pathname
    const active_company = pathOr(':companyid', ['match', 'params', 'companyid'], this.props);
    const settingroute = routes.filter(route => route.key === 'setting')[0]
    const companysetting = routes.filter(route => route.key === 'companysetting')[0]

    return (

      <div>
        <AntHeader style={{
          background: '#fff',
          padding: 0,
          height: 45,
          position: 'fixed',
          width: '100vw',
          zIndex: 10,
          left: 0,
          boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
        }}>
          {logo && <img className='company-logo' src={logo} alt="logo" />}
          <Menu
            onClick={this.handleClick}
            mode="horizontal"
          >            
              <SubMenu className="settings" style={{ float: 'right' }}
                title={<span>
                  <Icon type="setting" style={{ fontSize: '18px' }} />
                  {t('Settings')}
                  <Icon type="down" />
                </span>}
              >
                <Menu.Item key="setting:1">
                  <Link to={settingroute.path.replace(':companyid', active_company)}>
                    {t('Account Settings')}
                  </Link>
                </Menu.Item>
                

              {(active_company && pathname !== '/' && this.props.role && this.props.role == 'owner') &&
                <Menu.Item>
                  <Link to={companysetting.path.replace(':companyid', active_company)}>
                    {t('Company Settings')}
                  </Link>
                </Menu.Item>
              }
              
              {superReseller &&
                <Menu.Item>
                  <Link to={`/company/${active_company}/templatebuilder`}>
                  {t('Product Categories')}
                  </Link>
                </Menu.Item>
              }

              <Menu.Item key="settings:3">
                <div onClick={() => this.logout()}>{t('Sign Off')}</div>
              </Menu.Item>
             </SubMenu>


            <SubMenu style={{ float: 'right' }} title={<span><img src={company_icon} /> {this.props.companyName} <Icon type="down" /></span>}>
              {(myCompanies || []).filter(company => company && company[1] && company[1].id).map(company => (
                  <Menu.Item key={company[1].id}>
                    <div onClick={() =>this.selectCompany(company[1].id)}>{company[1].name || company[1].id}</div>
                  </Menu.Item>
              ))}
            </SubMenu>

            <SubMenu style={{ float: 'right' }} title={<span><Icon type="flag" />{this.getLanguage()} <Icon type="down" /></span>}>
              <Menu.Item key="en">
                <div onClick={() => this.props.changeLanguage('EN', this.props.auth.uid)}>English</div>
              </Menu.Item>
              <Menu.Item key="nl">
                <div onClick={() => this.props.changeLanguage('NL', this.props.auth.uid)}>Nederlands</div>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </AntHeader>
      </div>
    )
  }
}

const actions = {
  signout,
  resetAllState,
  resetState
}
export default compose(
  firestoreConnect((props, state) => {
    const uid = pathOr(null, ['firebase', 'auth', 'uid'], state.getState())
    return [
      { collection: 'companiesByUser', doc: uid, storeAs: 'companyIds' },
      { collection: 'languageByUser', doc: uid, storeAs: 'language' }
    ]
  }),
  connect(state => {
    let companyIds = state.firestore.data.companyIds
    return {
      companyIds,
      userLang: pathOr('EN', ['data', 'language', 'lang'], state.firestore)
    }
  }),
  firestoreConnect((props, state) => {
    const { companyIds } = props
    let paths = []
    if (companyIds) {
      Object.entries(companyIds).map(company => {
        if (company[1] !== 'support') {
          let path = {
            collection: 'companies',
            doc: company[0]
          }
          paths.push(path)
        }
      })
    }
    return paths
  }),
  connect((state, props) => {
    let { companyIds } = props
    let filtered_companyIds = []
    companyIds && Object.entries(companyIds).map(comp => {
      if (comp[1] !== 'affiliate' && comp[1] !== 'support') {
        filtered_companyIds.push(comp[0])
      }
    })
    const myCompanies = (state.firestore.data.companies && companyIds) && Object.entries(state.firestore.data.companies).filter(comp => filtered_companyIds.includes(comp[0]))
    const auth = state.firebase.auth
    const uid = auth.uid
    return {
      myCompanies,
      auth
    }
  }, actions),
)(withRouter(Header))
