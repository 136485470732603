import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'


window.ENVIRONMENT = 'zworks';
window.SERVER = 'remote';

const fbConfigs = {
  staging: {
    apiKey: 'AIzaSyC3r__xojhFweQb0evc74Y_ivDJcaxJ_2k',
    databaseURL: 'https://roms2-app.firebaseio.com',
    storageBucket: 'roms2-app.appspot.com',
    authDomain: 'roms2-app.firebaseapp.com',
    messagingSenderId: '454582030228',
    projectId: 'roms2-app'
  },
  production: {
    apiKey: 'AIzaSyCyRP9GqiSpPZefLrGeOdFwdBGKpDVWtZ8',
    authDomain: 'roms2-production.firebaseapp.com',
    databaseURL: 'https://roms2-production.firebaseio.com',
    projectId: 'roms2-production',
    storageBucket: 'roms2-production.appspot.com',
    messagingSenderId: '877083336799'
  },
  zworks: {
    apiKey: "AIzaSyDOsSavst9AlwW4aeum1SExHNhDj1ManC4",
    authDomain: "roms2---zworks.firebaseapp.com",
    projectId: "roms2---zworks",
    storageBucket: "roms2---zworks.appspot.com",
    messagingSenderId: "210416784174",
    appId: "1:210416784174:web:3ab37554d7da60270220bf"
  }
}

const fbFunctionsURLs = {
  staging: window.SERVER === 'local'
    ? 'http://localhost:5005/roms2-app/us-central1'
    : 'https://us-central1-roms2-app.cloudfunctions.net',
  production: window.SERVER === 'local'
    ? 'http://localhost:5005/roms2-production/us-central1'
    : 'https://us-central1-roms2-production.cloudfunctions.net',
  zworks: window.SERVER === 'local'
    ? 'http://localhost:5005/roms2---zworks/us-central1'
    : 'https://us-central1-roms2---zworks.cloudfunctions.net',
}

const fallbackUrs = window.ENVIRONMENT === 'production'
  ? 'https://roms2-production.uc.r.appspot.com'
  : 'https://roms2-app.uc.r.appspot.com'


const storageUrls = {
  staging: 'gs://roms2-app.appspot.com',
  production: 'gs://roms2-production.appspot.com'
}


export const fbFunctionsURL = fbFunctionsURLs[window.ENVIRONMENT]
export const fallbackUrl = fallbackUrs[window.ENVIRONMENT]
export const storageUrl = storageUrls[window.ENVIRONMENT]
const fbConfig = fbConfigs[window.ENVIRONMENT]


firebase.initializeApp(fbConfig)
const firestore = firebase.firestore()
const settings = { timestampsInSnapshots: true }
firestore.settings(settings)

export default firebase
