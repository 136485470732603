import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Nav from './nav/nav'
import { Layout, Breadcrumb } from 'antd'
import InviteMessage from '../invitations/InviteMessage';
import { getFirebase } from 'react-redux-firebase'
import Header from '../header/header'
import { connect } from 'react-redux';
import { pathOr } from 'ramda'
import { compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase'
import { AUTHROUTE } from '../../routes';

const { Content, Footer } = Layout;

const DefaultLayout = ({
  component: Component,
  ...rest
}) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;
  const t = rest.t

  if (authenticated) {

    return (
      <Route
        {...rest}
        render={matchProps => (
        <Layout style={{ minHeight: '100vh' }}>
          <InviteMessage t={t}/>
          <Layout>
            <Header language={rest.language} changeLanguage={rest.changeLanguage} t={t} />
            <Component role={rest.role} {...matchProps} t={t}/>
            <Footer style={{ textAlign: 'center' }}>
              Reach ©2022
            </Footer>
          </Layout>
        </Layout>
      )}/>
    )
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        // <Layout className="session">
        //   <Auth />
        // </Layout>
        <Redirect to={AUTHROUTE} />
    )}/>
  )
};

export default compose(
  connect(state => {
    let isSupport = pathOr(false, ['firebase', 'profile', 'supportAgent'], state);

    return {
      role: isSupport ? 'support' : null
    }
  }),
)(DefaultLayout)