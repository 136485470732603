import './nav.css'
import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Logo from './components/logo'
import LogoCollapsed from './components/logocollapsed'
import {Layout, Menu, Icon} from 'antd'
import routes from '../../../routes'
import { pathOr } from 'ramda'
const { SubMenu } = Menu
const {Sider} = Layout

export class Nav extends Component {
  
  state = {
    collapsed: false
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {

    const { location, t, companyId, kpnAgent } = this.props
    const active_company = pathOr(':companyid', ['match', 'params', 'companyid'], this.props);
    const { role, availableCategories } = this.props;
    console.log('location', location.pathname)

    return (
        <Sider
          style={{ minHeight: '100vh', backgroundColor: '#092c4c', boxShadow: 'rgb(136 136 136) 3px -1px 8px 0px' }}
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          theme="dark"
          className="sider navigation"
        >
          <style>
            {`.ant-layout-sider-children {
              position: fixed;
            }`}
          </style>
          <ReactCSSTransitionGroup
            transitionName="logo"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
          </ReactCSSTransitionGroup>
          <Menu theme="dark" mode="vertical" selectedKeys={[location.pathname]}>
            {routes.filter(route =>
              route.menu === true &&
              (route.permission.includes(role) || route.key === 'companies' || role === 'support') &&
              (!route.onlySuperResellers || companyId === 'y1wzuJn9DLroI2usouLv') &&
              (!kpnAgent || route.permission.includes('kpnAgent'))
            ).map(route => {
              if (route.key === 'compare') {
                return (
                  <SubMenu 
                    key={route.path}
                    title={
                      <span>
                        <Icon type={route.icon} />
                        <span className={location.pathname.includes('compare') ? "nav-text active" : "nav-text"}>{t(route.label)}</span>
                      </span>
                    }
                  >
                    {availableCategories.includes('energy') &&
                      <Menu.Item key='energy'>
                        <Link to={`/company/${active_company}/compare/energy`}>
                          <i className="flaticon-lightning-1" />
                          <span className="nav-text">{t('Energy')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {availableCategories.includes('home') &&
                      <Menu.Item key='home'>
                        <Link to={`/company/${active_company}/compare/home`}>
                          <i className="flaticon-smart-home-1" />
                          <span className="nav-text">{t('Home')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {availableCategories.includes('mobile') &&
                      <Menu.Item key='mobile'>
                        <Link to={`/company/${active_company}/compare/mobile`}>
                          <i className="flaticon-smartphone-1" />
                          <span className="nav-text">{t('Mobile')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {availableCategories.includes('lead') &&
                      <Menu.Item key='lead'>
                        <Link to={`/company/${active_company}/compare/lead`}>
                          <Icon type="idcard" />
                          <span className="nav-text">{t('Lead')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {availableCategories.includes('media') &&
                      <Menu.Item key='media'>
                        <Link to={`/company/${active_company}/compare/media`}>
                          <Icon type="video-camera" />
                          <span className="nav-text">{t('Media')}</span>
                        </Link>
                      </Menu.Item>
                    }
                  </SubMenu>
                )
              } else {
                return (
                  <Menu.Item key={route.path}>
                    <Link to={route.path.replace(':companyid', active_company)}>
                      <Icon type={route.icon} />
                      <span className={location.pathname.includes(route.key) ? "nav-text active" : "nav-text"}>{t(route.label)}</span>
                    </Link>
                  </Menu.Item>
                )
              }
            })}
          </Menu>
        </Sider>
    )
  }
}

export default withRouter(Nav)
