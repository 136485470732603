import { SubmissionError } from 'redux-form'
import history from '../../utils/history'
import { SystemError } from '../../utils/error'
import { message } from 'antd'
import moment from 'moment'
import axios from 'axios'

export const login = (creds) => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            let user = await getFirebase().auth().signInWithEmailAndPassword(creds.email, creds.password)
            let uid = user.user.uid
            
            // const ref = getFirebase().database().ref(`/sessions/${uid}`);
            // ref.onDisconnect().set({ isOnline: false })
            // ref.once('value').then(async (snapshot) => {
            //     const data = snapshot.val();
            //     if (data && data.isOnline) {
            //         let msg = `Dit account is al ingelogd om ${data.time} via IP-adres ${data.ip} (geschatte locatie: ${data.location}). Er kan maar één sessie tegelijk actief zijn. Ben jij dit niet? Neem contact op met support.`
            //         message.error(msg, 10)
            //         await getFirebase().auth().signOut()
            //         history.push('/')
            //     }
            //   });
            history.push('/create')
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Missing field... did you type in everything?" })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}


export const signup = (creds) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        try {
            // Create the user in Firebase
            let userForFirebase = await getFirebase().auth().createUserWithEmailAndPassword(creds.email, creds.password)
            // Create the user in Firestore
            let userForFirestore = {
                first_name: creds.first_name,
                last_name: creds.last_name,
                email: creds.email,
                country: creds.country ? creds.country : null,
                phone: creds.phone,
                createdAt: getFirestore().FieldValue.serverTimestamp()
            }
            await getFirestore().collection('users').doc(userForFirebase.user.uid).set(userForFirestore)
            history.push('/create')
            
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Missing field... did you type in everything?" })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const resetPass = (creds) => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            await getFirebase().auth().sendPasswordResetEmail(creds.email).then(() => {
            })
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Missing field... did you type in everything?" })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signout = () => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            let uid = getFirebase().auth().currentUser && getFirebase().auth().currentUser.uid
            const ref = getFirebase().database().ref(`/sessions/${uid}`);
            ref.set({
              isOnline: false
            })
            await getFirebase().auth().signOut()
            history.push('/')
        } catch (error) {
            SystemError(error)
        }
    }
}
