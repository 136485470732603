import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { getFirebase } from "react-redux-firebase";
import "./layouts.css";
import reach from "../../images/reach.png";

const { Content, Footer, Sider } = Layout;

const DefaultLayout = ({ component: Component, ...rest }) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;

  useEffect(() => {
    rest.changeLanguage("NL");
  }, []);

  if (!authenticated) {
    return (
      <div>
        <Route
          {...rest}
          render={matchProps => (
            <Layout style={{ minHeight: "100vh" }}>
              <div className="wallpaper">
                <Content>
                  <div className="content">
                    <img className="reach" src={reach} />
                    <Component {...matchProps} t={rest.t} />
                  </div>
                  <footer className="footer" style={{ textAlign: "center" }}>
                    Reach ©2022
                  </footer>
                </Content>
              </div>
            </Layout>
          )}
        />
      </div>
    );
  }

  return <Route {...rest} render={() => <Redirect to="/" />} />;
};

export default DefaultLayout;
