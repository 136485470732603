import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Nav from './nav/nav'
import { Layout, Breadcrumb } from 'antd'
import Header from '../header/header'
import { AUTHROUTE } from '../../routes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { pathOr } from 'ramda'
const { Content, Footer } = Layout;

class DefaultLayout extends React.Component {


  componentDidUpdate = async (prevProps) => {
    const { firestore, setAvailableCategories } = this.props
    let availableCategories = []
    if (this.props?.activeCompanyKey && !prevProps.activeCompanyKey) {
      let permissionSnap = await firestore.collection('productPermissions').doc(this.props.activeCompanyKey).get()
      let productIds = Object.entries(permissionSnap.data() || {})
        .filter(prod => !prod[1].deleted)
        .map( prod => prod[0])
      await Promise.all(productIds.map( id => {
        return firestore.collection('products').doc(id).get()
          .then(productSnap => {
            let product = productSnap.data()
            if (product && !availableCategories.includes(product.category)) {
              availableCategories.push(product.category)
            }
          })
      }))
      setAvailableCategories(availableCategories)
    }
  }


  render() {

    const {role, permission, activeCompanyKey, kpnAgent, availableCategories } = this.props
    const firebase = this.props.firebase
    const auth = firebase.auth();
    const authenticated = auth.currentUser;
    const Component = this.props.component
    const routerProps = {
      computedMatch: this.props.computedMatch,
      exact: this.props.exact,
      icon: this.props.icon,
      label: this.props.label,
      layout: this.props.layout,
      location: this.props.location,
      menu: this.props.menu,
      path: this.props.path,
      private: this.props.private,
    }
    if (authenticated) {
      // if ( permission && role && !permission.includes(role) || (this.props.onlySuperResellers && this.props.activeCompanyKey !== 'y1wzuJn9DLroI2usouLv')){
      //   return (
      //     <Route
      //       {...routerProps}
      //       render={matchProps => (
      //         <Redirect to="/" />
      //     )}/>
      //   )
      // }
      return (
        <Route
          {...routerProps}
          render={matchProps => (
            <div>
              {activeCompanyKey === 'dYDUNSCsw5I0XfavahIR' && //y1wzuJn9DLroI2usouLv - dYDUNSCsw5I0XfavahIR
                <style>{saleskontoorStyleing}</style>
              }
              <Layout style={{ minHeight: '100vh' }}>
                <Nav 
                  kpnAgent={kpnAgent} 
                  permission={this.props.permission} 
                  t={this.props.t} 
                  role={role} 
                  companyId={this.props.activeCompanyKey}
                  availableCategories={availableCategories}
                />
                <Layout>
                  <Header 
                    superReseller={this.props.superReseller} 
                    language={this.props.language} 
                    logo={this.props.bannerURL} 
                    role={this.props.role} 
                    t={this.props.t} 
                    changeLanguage={this.props.changeLanguage} 
                    companyName={this.props.companyName}
                  />
                  <Content style={{ margin: '0 16px' }}>
                    <div style={{ padding: 24, minHeight: 360, paddingTop: 70 }}>
                      {activeCompanyKey && 
                        <Component 
                          {...matchProps} 
                          activeCompanyKey={activeCompanyKey} 
                          superReseller={this.props.superReseller} 
                          role={this.props.role} 
                          t={this.props.t} 
                          isSupport={this.props.isSupport}
                          orderToOpen={this.props.orderToOpen}
                          setOrderToOpen={this.props.setOrderToOpen}
                        />}
                    </div>
                  </Content>
                  <Footer style={{ textAlign: 'center' }}>
                    Reach ©2022
                  </Footer>
                </Layout>
              </Layout>
            </div>
        )}/>
      )
    }
    return (
      <Route
        {...routerProps}
        render={matchProps => (
          // <Layout className="session">
          //   <Auth />
          // </Layout>
          <Redirect to={AUTHROUTE} />
      )}/>
    )
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect( (props, state) => {
    const uid  = pathOr(null, ['firebase', 'auth', 'uid'], state.getState())
    return [{collection: 'companiesByUser', doc: uid, storeAs: 'companyIds'}]
  }),
  connect( state => {
    let myCompanies = state.firestore.data.companyIds
    return {
      myCompanies
    }
  }),
  firestoreConnect( (props, state) => {
    const active_company = pathOr(null, ['computedMatch', 'params', 'companyid'], props);
    const isSupport = pathOr(false, ['firebase', 'profile', 'supportAgent'], state.getState());
    const companies = pathOr({}, ['firestore', 'data', 'companies'], state.getState());
    const uid = pathOr('123', ['firebase', 'auth', 'uid'], state.getState());
    const myCompanies = props.myCompanies

    
    if (active_company && active_company !== 'none' && myCompanies && !isSupport) {
      let paths = []
      Object.entries(myCompanies).map( company => {
        const path = {
          collection: 'companies',
          where: [
            ['slug', '==', active_company],
            ['id', '==', company[0]]
          ],
          storeAs: 'active_company'
        }
        paths.push(path)
      })
      paths.push({
        collection: 'companies',
        where: [
          ['slug', '==', active_company],
          ['acceesibleFor', 'array-contains', uid]
        ],
        storeAs: 'active_company'
      })
      if (state.getState().firestore.data.active_company) {
        for (let key in state.getState().firestore.data.active_company) {
          if (state.getState().firestore.data.active_company[key].slug !== active_company) {
            delete state.getState().firestore.data.active_company[key]
          }
        }
      }
      return paths
    } else if (isSupport) {

      return active_company === 'bespaardirect'
        ? [{ collection: 'companies', where: ['id','==','y1wzuJn9DLroI2usouLv'], storeAs: 'active_company'}]
        : [{
          collection: 'companies',
          where: [
            ['slug', '==', active_company]
          ],
          storeAs: 'active_company'
        }]
    } else {
      return []
    }
  }),
  connect( (state, props) => {
    const slug = pathOr('', ['computedMatch', 'params', 'companyid'], props);
    let active_company = pathOr({}, ['firestore', 'data', 'active_company'], state);
    let name = pathOr(null, ['firebase', 'profile', 'name'], state);
    let companyName;
    let logoUrl;
    let bannerURL;
    let activeCompanyKey
    let role;
    let superReseller = false
    const isSupport = pathOr(false, ['firebase', 'profile', 'supportAgent'], state);
    let company = Object.entries(active_company || {}).find(comp => comp[1].slug === slug)
    if (company) {
      company = company[1]
      companyName = company.name
      logoUrl = company.logoUrl
      activeCompanyKey = company.id
      bannerURL = company.bannerURL
       role = pathOr(null, ['data', 'companyIds', activeCompanyKey], state.firestore)
       superReseller = pathOr(false,[activeCompanyKey, 'superReseller'] ,active_company)
    }

    return {
      role: isSupport ? 'owner' : role,
      isSupport,
      activeCompanyKey,
      companyName,
      logoUrl,
      bannerURL,
      name,
      superReseller,
      kpnAgent:  pathOr(false,['firebase','profile', 'kpn'], state),
    }
  }),
)(DefaultLayout)



const getAvailableProductTypes = (products, productIds) => {
  let categories = []
  Object.entries(products).map( prod => {
    let product = prod[1]
    if (product && !categories.includes(product.category) && productIds.includes(prod[0])) {
      categories.push(product.category)
    }
  })
  return categories;
}

const saleskontoorStyleing = `
    .ant-layout-sider {
      background: #faa21e;
    }
    .ant-menu-dark {
      background: #faa21e;
    }
    .ant-btn-primary {
      background-color: #01b2ec;
      border-color: #01b2ec;
    }
    .ant-menu .ant-menu-item a {
      color: black;
    }
    div.ant-layout-sider-trigger {
      background-color: #01b2ec;
    }
  `
